import React from "react";
import ReactDynamicImport from 'react-dynamic-import';
import { detectMob } from "@src/Helper";

const loader = () => {
  if (!detectMob()) {
    return (
      import('@components/ExmPoint/ExmPoint')
    )
  } else {
    return (
      import('@components/ExmPoint/ExmPointMobile')
    )
  }
}

const ExmPoint = ReactDynamicImport({ loader })

const ExmPointPage = () => <ExmPoint />

export default ExmPointPage;
